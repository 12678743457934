import "./Header.css";
import { useAccount } from "wagmi";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useSignIn } from "hooks";
import { ReactComponent as ProfileIcon } from "assets/svg/profile-icon.svg";
import { ReactComponent as ArbolLogo } from "assets/svg/arbol-logo.svg";

export default function Header({ loggedAs = "", isDemo = false }) {
  const history = useHistory();
  const { signOut } = useSignIn();
  const { address, isConnected } = useAccount();
  const displayAddress = address?.substr(0, 5) + "..." + address?.substr(-4);
  const capitalizeUserName = loggedAs ? loggedAs.charAt(0).toUpperCase() + loggedAs.slice(1) : "";

  const items = [{ key: "1", label: "Sign Out" }];

  const handleMenuClick = async e => {
    if (e.key === "1") {
      await signOut();
      history.push(isDemo ? "demo" : "/");
    }
  };

  return (
    <header>
      <div>
        <ArbolLogo />
        <h1>dRe Lifecycle</h1>
      </div>

      {isConnected && loggedAs && (
        <Dropdown
          menu={{
            items,
            onClick: handleMenuClick,
          }}
        >
          <div className="profile">
            {/* Broker | Reinsurer | Cedent  */}
            {capitalizeUserName || displayAddress}

            <div className="profile-avatar">
              <ProfileIcon />
              <CaretDownOutlined style={{ fontSize: 11 }} />
            </div>
          </div>
        </Dropdown>
      )}
    </header>
  );
}
