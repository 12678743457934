export const sortObject = unsortedObject => {
  const sortedContracts = unsortedObject.sort((a, b) => {
    const keyA = a.end;
    const keyB = b.end;

    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  return sortedContracts;
};
