import axios from "axios";
import { useState } from "react";
import Web3 from "web3";
import { recoverTypedSignature } from "@metamask/eth-sig-util";
import { message } from "antd";

export const useSignContract = () => {
  const [loadingSignature, setSigLoading] = useState(false);
  const [newTimeStamps, setTimeStamps] = useState({
    cedentSignedTimestamp: "",
    reinsurerSignedTimestamp: "",
    cedentSigned: false,
    reinsurerSigned: false,
  });
  const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

  const signContract = async uid => {
    setSigLoading(true);
    const from = await web3.eth.getAccounts();
    const signer = from[0];

    const signatureParameters = await axios.post(
      `${process.env.REACT_APP_SIWE_SERVER}/signature`,
      { signer },
      { withCredentials: true },
    );

    const msgParams = JSON.stringify({
      domain: {
        chainId: signatureParameters.data?.chainId,
        name: "SignatureVerifier",
        verifyingContract: signatureParameters.data?.verifyingContract,
        version: "1",
      },

      message: {
        owner: signer,
        nonce: signatureParameters.data?.nonce,
        deadline: signatureParameters.data?.deadline,
      },

      primaryType: "Verify",
      types: {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        Verify: [
          { name: "owner", type: "address" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      },
    });

    const params = [signer, msgParams];
    const method = "eth_signTypedData_v4";

    web3.currentProvider.sendAsync(
      {
        method,
        params,
        from: signer,
      },
      async (err, result) => {
        if (err) {
          message.error("Failed: " + err.message?.toString(), 5);
          throw err.message?.toString();
        }

        if (result.error) {
          message.error("Failed: " + result.error?.message.toString(), 5);
          throw result.error?.message.toString();
        }

        const recovered = recoverTypedSignature({
          data: JSON.parse(msgParams),
          signature: result.result,
          version: "V4",
        });

        try {
          if (Web3.utils.toChecksumAddress(recovered) === Web3.utils.toChecksumAddress(signer)) {
            const authorizationSubmission = {
              uid,
              signature: result.result,
              authorizer: signer,
              deadline: signatureParameters.data?.deadline,
            };

            const newTimeStamps = await axios.post(
              `${process.env.REACT_APP_SIWE_SERVER}/sign-authorization`,
              { authorizationSubmission },
              { withCredentials: true },
            );

            if (newTimeStamps.data) setTimeStamps(newTimeStamps.data);
            message.success("Contract signed successfully!", 5);
            setSigLoading(false);
          } else {
            message.error("Failed to verify signer when comparing " + result + " to " + from, 5);
          }
        } catch (err) {
          message.error("Failed: " + err);
          setSigLoading(false);
        }
      },
    );
  };

  return {
    signContract,
    loadingSignature,
    newTimeStamps,
  };
};

export default useSignContract;
