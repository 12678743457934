import { Route, Redirect } from "react-router-dom";
import { useSignIn } from "hooks";

export default function GuardedRoute({ children, isDemo = false, ...rest }) {
  const { isLoggedIn } = useSignIn();

  return (
    <Route
      {...rest}
      render={() => {
        return isLoggedIn === true ? children : <Redirect to={isDemo === true ? "/demo" : "/"} />;
      }}
    />
  );
}
