import "./CollapsiblePanel.css";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar-icon.svg";
import { ReactComponent as GithubIcon } from "assets/svg/github-icon.svg";
import { ReactComponent as ArrowIcon } from "assets/svg/arrow-icon.svg";

export default function CollapsiblePanel({ isOpen = false, active, expired, children, onClick, header = {} }) {
  return (
    <>
      <div className="collapsible-header" onClick={onClick}>
        <div className={`contract-status ${expired || active ? "" : "pending"}`}>
          <div
            className={`${isOpen ? "chevron-open" : "chevron-close"} ${
              expired || active ? "active-chevron" : "pending-chevron"
            }`}
          >
            <ArrowIcon />
          </div>
          <h3>{expired ? "Expired Agreement" : active ? "Active Agreement" : "Awaiting Smart Contract Execution"}</h3>
        </div>

        <div>
          <strong>Confirmation ID Number</strong>
          <p>{header.confirmationNumber}</p>
        </div>

        <div>
          <strong>Agreement Name</strong>
          <p>{header.contractName}</p>
        </div>

        <div className="header-date">
          <strong>Policy Start and End Dates</strong>
          <p>
            {header.policyPeriod.start} <CalendarIcon /> - {header.policyPeriod.end} <CalendarIcon />
          </p>
        </div>

        <div>
          <strong>Cedent</strong>
          <p>{header.clientCompany}</p>
        </div>

        <div onClick={e => e.stopPropagation()}>
          <strong>View Code</strong>
          <p>
            <GithubIcon />
            <a href={header.githubUri} target="_blank" rel="noreferrer">
              Open Github
            </a>
          </p>
        </div>
      </div>

      {children}
    </>
  );
}
