import { Divider } from "antd";
import "./PaymentSummary.css";

import Card from "./Card";
import { USDollar } from "helpers";

export default function PaymentSummary({ hasSubperiods = false, content = {} }) {
  return (
    <Card className={!hasSubperiods ? "payment-summary" : "payment-summary-pending"}>
      <Card.Header>
        <h3>Payment Summary</h3>
      </Card.Header>

      {!hasSubperiods ? (
        <Card.Content>
          <div className="payment-card-flow">
            <div className="payment-total-wrapper">
              <div className="payment-total">
                <div className="contract-subject-loss">
                  <h4>Contract Loss Amount</h4>
                  <p>{content.subjectLoss}</p>
                </div>

                <div className="contract-total-limit">
                  <h4>Contract total limit </h4>
                  <p>{content.totalLimit}</p>
                </div>
              </div>

              <div className="progress-bar__container">
                {(content.progress && (
                  <div
                    className="progress-bar"
                    style={{
                      width: `${content.progress}%`,
                    }}
                  >
                    <span className="progress-bar__text">{`${content.progress}%`}</span>
                  </div>
                )) || <div style={{ width: "15px", color: "white", margin: "0 auto" }}>0%</div>}
              </div>
            </div>

            <Divider
              type="vertical"
              style={{ height: "100%", margin: "0 32px", borderLeft: "1px solid rgba(0, 0, 0, 0.1)" }}
            />

            <div className="payment-per-limit">
              <div>
                <h4>per event limit</h4>
                <p>{content.perEventLimit}</p>
              </div>

              <div>
                <h4>per event attachment</h4>
                <p>{content.perEventAttachment}</p>
              </div>
            </div>
          </div>
        </Card.Content>
      ) : (
        <Card.Content>
          <div className="payment-card-flow">
            <div className="payment-total-wrapper payment-limits">
              <div className="payment-total">
                <div className="contract-subject-loss">
                  <h4>Contract Loss Amount</h4>
                  <p>{content.subjectLoss}</p>
                </div>

                <div className="contract-total-limit">
                  <h4>Contract total limit </h4>
                  <p>{content.totalLimit}</p>
                </div>
              </div>

              <div className="progress-bar__container">
                {(content.progress && (
                  <div
                    className="progress-bar"
                    style={{
                      width: `${content.progress}%`,
                    }}
                  >
                    <span className="progress-bar__text">{`${content.progress}%`}</span>
                  </div>
                )) || <div style={{ width: "15px", color: "white", margin: "0 auto" }}>0%</div>}
              </div>

              <div className="payment-per-limit">
                <div>
                  <h4>per event limit</h4>
                  <p>{content.perEventLimit}</p>
                </div>

                <div>
                  <h4>per event attachment</h4>
                  <p>{content.perEventAttachment}</p>
                </div>
              </div>
            </div>

            <Divider
              type="vertical"
              style={{
                height: "100%",
                margin: "0 32px",
                borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            />

            <div className="payment-reinsurer-period">
              <p>Note: Loss Amounts subject to following Reinsurance Period Aggregate Sublimits:</p>

              <div className="reinsurance-table-container">
                <div className="reinsurance-period-table">
                  <div>Reinsurance Period</div>
                  <Divider
                    type="vertical"
                    style={{
                      height: "15px",
                      margin: "auto 0",
                      borderRight: "2px solid rgba(0, 0, 0, 0.2)",
                      borderLeft: "none",
                    }}
                  />
                  <div>Aggregate Sublimit</div>
                </div>

                {content.subPeriods?.map((period, index) => (
                  <div className="reinsurance-period-table" key={index}>
                    <div>
                      <p>{`${period.start} - ${period.end}`}</p>
                    </div>
                    <div>
                      <p>{USDollar.format(period.subLimit || 0)}</p>
                    </div>
                  </div>
                ))}
              </div>

              <p>Payment Summary, Aggregate Sublimits, and Loss Amounts on 100% Basis</p>
            </div>
          </div>
        </Card.Content>
      )}
    </Card>
  );
}
