import "./Card.css";

function Header({ className = "", children }) {
  return <div className={`card-header ${className}`}>{children}</div>;
}

function Content({ className = "", children }) {
  return <div className={`card-content ${className}`}>{children}</div>;
}

function Card({ className = "", children }) {
  return <div className={`card ${className}`}>{children}</div>;
}

Card.Header = Header;
Card.Content = Content;

export default Card;
