import "./Stakeholders.css";
import { useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "helpers";
import { Tooltip } from "antd";

import Card from "./Card";

const tooltip_color = "rgba(45, 49, 62, 0.8)";

export default function Stakeholders({ active = false, expired = false, content = {} }) {
  const [copied, setCopied] = useState([]);
  const displayBrokerAddress = content.broker.ID?.substr(0, 5) + "..." + content.broker.ID?.substr(-4);
  const displayCedentAddress = content.cedent.ID?.substr(0, 5) + "..." + content.cedent.ID?.substr(-4);
  const displayReinsurerAddress = content.reinsurer.ID?.substr(0, 5) + "..." + content.reinsurer.ID?.substr(-4);

  const SigningStatusBadge = ({ signedDate, isSigned }) => {
    return (
      !active &&
      !expired && (
        <span className={`badge-ribbon ${signedDate && isSigned ? "" : "signing-pending"}`}>
          {signedDate && isSigned ? `Signed ${signedDate}` : "Awaiting Signature"}
        </span>
      )
    );
  };

  const copyText = address => {
    const text = copyToClipboard(address);
    setCopied(items => [...items, text]);
  };

  return (
    <>
      <Card className="stakeholders">
        <Card.Header>
          <h3>Participants</h3>
        </Card.Header>

        <Card.Content>
          <div>
            <p>
              <span>
                <strong>Broker ID</strong>
              </span>

              {content.showBroker && (
                <Tooltip title={content.broker.ID} placement="bottomRight" color={tooltip_color}>
                  <span className="address-container">
                    {displayBrokerAddress}
                    <CopyOutlined
                      size="small"
                      style={{ color: copied.includes(content.broker.ID) ? "green" : "" }}
                      onClick={() => copyText(content.broker.ID)}
                    />
                  </span>
                </Tooltip>
              )}
            </p>

            <p>
              <span>
                <strong>Cedent ID</strong>
                <SigningStatusBadge signedDate={content.cedent.signedDate} isSigned={content.cedent.isSigned} />
              </span>

              <Tooltip title={content.cedent.ID} placement="bottomRight" color={tooltip_color}>
                <span className="address-container">
                  {displayCedentAddress}
                  <CopyOutlined
                    size="small"
                    style={{ color: copied.includes(content.cedent.ID) ? "green" : "" }}
                    onClick={() => copyText(content.cedent.ID)}
                  />
                </span>
              </Tooltip>
            </p>

            {content.showReinsurer && (
              <p>
                <span>
                  <strong>Reinsurer ID</strong>
                  <SigningStatusBadge signedDate={content.reinsurer.signedDate} isSigned={content.reinsurer.isSigned} />
                </span>

                <Tooltip title={content.reinsurer.ID} placement="bottomRight" color={tooltip_color}>
                  <span className="address-container">
                    {displayReinsurerAddress}
                    <CopyOutlined
                      size="small"
                      style={{ color: copied.includes(content.reinsurer.ID) ? "green" : "" }}
                      onClick={() => copyText(content.reinsurer.ID)}
                    />
                  </span>
                </Tooltip>
              </p>
            )}
          </div>
        </Card.Content>
      </Card>
    </>
  );
}
