import "./App.css";
import "@rainbow-me/rainbowkit/styles.css";
import { Route, Switch } from "react-router-dom";
import { createClient, WagmiConfig, configureChains } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { metaMaskWallet, coinbaseWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import GuardedRoute from "routes/GuardedRoute";
import { SignInProvider } from "hooks";
import { StormContract, Connect, PkeyGenerate, ResetAuthorizations } from "./views";

export const devCanopy = {
  id: parseInt(process.env.REACT_APP_CHAIN_ID || ""),
  name: "DevCanopy",
  network: "dev-canopy",
  nativeCurrency: {
    decimals: 18,
    name: "DevCanopy",
    symbol: "ETH",
  },
  rpcUrls: {
    public: {
      http: [process.env.REACT_APP_RPC_ENDPOINT],
    },
    default: {
      http: [process.env.REACT_APP_RPC_ENDPOINT],
    },
  },
};

const { chains, provider } = configureChains([devCanopy], [publicProvider()]);

const connectors = connectorsForWallets([
  {
    groupName: "Available Wallets",
    wallets: [metaMaskWallet({ chains }), coinbaseWallet({ chains }), walletConnectWallet({ chains })],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export default function App() {
  return (
    <div className="dReLifecycle-container">
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} modalSize="compact">
          <SignInProvider>
            <Switch>
              {/* Contract Routes */}
              <Route exact path="/">
                <Connect />
              </Route>
              {process.env.REACT_APP_DEBUG === "true" && (
                <Route exact path="/reset">
                  <ResetAuthorizations />
                </Route>
              )}
              <GuardedRoute exact path="/dashboard">
                <StormContract />
              </GuardedRoute>

              {/* Demo Routes */}
              <Route exact path="/public-key">
                <PkeyGenerate />
              </Route>
              <Route exact path="/demo">
                <Connect isDemo={true} />
              </Route>
              <GuardedRoute exact path="/demo-dashboard" isDemo={true}>
                <StormContract isDemo={true} />
              </GuardedRoute>
            </Switch>
          </SignInProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}
