import { Button } from "antd";
import "./ContractDocument.css";

import Card from "./Card";
import { ReactComponent as DownloadIcon } from "assets/svg/download-icon.svg";

export default function ContractDocument({ hasSubperiods = false, content = {}, downloadFile = () => {} }) {
  return (
    <Card className={`contract-document ${hasSubperiods ? "contract-document-pending" : ""}`}>
      <Card.Header>
        <h3>Operative Documents</h3>
      </Card.Header>

      <Card.Content>
        {content?.map(
          (contractDocument, index) =>
            contractDocument.fileUri && (
              <div className="document-info" key={index}>
                <p>
                  <strong>{contractDocument.name}</strong>
                </p>
                <p style={{ textAlign: "center" }}>{contractDocument.type.toUpperCase()}</p>
                <Button
                  shape="round"
                  onClick={() => {
                    console.log(`Downloading file ${contractDocument.fileUri} with uid ${contractDocument.uid}`);
                    downloadFile("data", contractDocument.uid, contractDocument.fileUri);
                  }}
                  icon={<DownloadIcon />}
                />
              </div>
            ),
        )}
      </Card.Content>
    </Card>
  );
}
