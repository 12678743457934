import React from "react";
import { Button } from "antd";
import "./SignCallToAction.css";

export default function SignCallToAction({ onSign = () => {}, loading = false }) {
  return (
    <div className="sign-transaction">
      <Button type="primary" loading={loading} onClick={onSign}>
        {loading ? "Signing.." : "Sign"}
      </Button>
      <p>Clicking "Sign" will initiate a transaction in the Metamask browser extension.</p>
    </div>
  );
}
