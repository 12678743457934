import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { Header } from "components/StormContract";
import { useAccount } from "wagmi";
import {
  useConnectModal,
  // ConnectButton
} from "@rainbow-me/rainbowkit";
import { useSignIn } from "hooks";
import { Button, Result } from "antd";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { copyToClipboard } from "helpers";

export default function PkeyGenerate() {
  const { loading, pKey, generatePKey } = useSignIn();
  const [triggerGeneratePubKey, setGeneratePubKeyTrigger] = useState(false);
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();

  const [copied, setCopied] = useState("");
  // const history = useHistory();

  const handleClick = async () => {
    if (!isConnected && openConnectModal) {
      openConnectModal();
      setGeneratePubKeyTrigger(true);
    }
  };

  const recoverPubKey = async () => await generatePKey();

  const copyText = address => {
    const text = copyToClipboard(address);
    setCopied(items => [...items, text]);
  };

  useEffect(() => {
    if (isConnected && triggerGeneratePubKey) {
      recoverPubKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  const renderPkeyGeneratePage = () => (
    <>
      <Header />

      <div
        className="content"
        style={{
          marginTop: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          key="connect"
          type="primary"
          loading={loading}
          style={{ width: 300, height: 50 }}
          onClick={isConnected ? recoverPubKey : handleClick}
        >
          {isConnected ? "Generate Public Key" : "Connect Wallet with Metamask"}
        </Button>

        {pKey && (
          <Result
            status="success"
            icon={false}
            title={
              <div>
                <p>Public Key Generated Successfully!</p>
                <p>
                  <strong>{pKey}</strong>
                </p>
              </div>
            }
            extra={[
              <Button style={{ width: 200 }} type="primary" key="copy-text" onClick={() => copyText(pKey)}>
                Copy Key
                {copied ? <CheckOutlined size="small" /> : <CopyOutlined size="small" />}
              </Button>,
              // <Button style={{ width: 200 }} key="sign-in" onClick={() => history.push("/")}>
              //   Go to Sign In Page
              // </Button>,
            ]}
          />
        )}
      </div>
    </>
  );

  return renderPkeyGeneratePage();
}
