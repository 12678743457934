import axios from "axios";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSignIn, useSignContract } from "hooks";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import { USDollar, sortObject } from "helpers";

import {
  Header,
  Collapsible,
  StormTable,
  PaymentSummary,
  ContractDocument,
  Stakeholders,
  SignCallToAction,
} from "components/StormContract";

// NOTE - This is not a valid UID
const DEMO_UID = 6609276246879400000;

export default function StormContract({ authenticated, isDemo = false }) {
  const { loggedAs, getUserAddress } = useSignIn(isDemo);
  const { signContract, newTimeStamps, loadingSignature } = useSignContract();
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [isSignedForDemo, setSignedForDemo] = useState(false);
  const isBroker = ["broker", "reinsurer"].includes(loggedAs);

  const handleExport = (contractName, rows) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    const columnTitle = Object.keys(rows[0]).join(",");
    csvContent += columnTitle + "\r\n";

    rows.forEach(function (rowArray) {
      const row = Object.values(rowArray).join(",");
      csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${contractName}_storm_document.csv`);
    document.body.appendChild(link); // NOTE - Required for FF

    link.click();
  };

  const requestDownload = async (base = "data", uid = "", filename = "") => {
    if (base && uid && filename) {
      try {
        // TODO - Move axios request outside JSX component
        const res = await axios.get(`${process.env.REACT_APP_SIWE_SERVER}/file/${base}/${uid}/${filename}`, {
          withCredentials: true,
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const fileBlob = new Blob([res.data], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${base}/${uid}/${filename}`);
        document.body.appendChild(link);

        link.click();
      } catch (err) {
        message.error("Failed to Download File: " + err, 5);
      }
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const address = await getUserAddress();
      const contractEndpoint =
        isDemo === true
          ? `${process.env.REACT_APP_SIWE_SERVER}/contract-data-demo/${address}`
          : `${process.env.REACT_APP_SIWE_SERVER}/contract-data/${address}`;

      const res = await axios.get(contractEndpoint, {
        withCredentials: true,
      });

      if (res.status === 200) {
        let sortedContracts = sortObject(res.data);

        sortedContracts = sortedContracts.map((contract, index) => ({
          ...contract,
          storms: contract.storms?.map(item => ({
            ...item,
            grossLoss: USDollar.format(item.grossLoss || 0),
            subjectLoss: USDollar.format(item.subjectLoss || 0),
          })),
        }));

        setContracts(sortedContracts);
      }
      setLoading(false);
    } catch (err) {
      message.error("Failed to get Contract Data: " + err, 5);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDemo && newTimeStamps.cedentSignedTimestamp !== "") {
      setSignedForDemo(true);
    }
  }, [newTimeStamps, isDemo]);

  if (authenticated === false) return <Redirect push to="/" />;

  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  const renderContent = () => (
    <div className="content">
      <h2>Dashboard View</h2>
      {contracts.length === 0 && (
        <div>
          <p>No contracts found.</p>
        </div>
      )}

      {contracts?.map((item, index) => {
        const active = item.active || (newTimeStamps.cedentSigned && newTimeStamps.reinsurerSigned);
        const showSignToUser =
          loggedAs === "cedent" || (loggedAs !== "reinsurer" && process.env.REACT_APP_DEBUG === "true")
            ? isSignedForDemo || newTimeStamps.cedentSigned || item.timestamps.cedentSigned
            : isSignedForDemo || newTimeStamps.reinsurerSigned || item.timestamps.reinsurerSigned;

        return (
          <Collapsible
            key={index}
            active={active || isSignedForDemo}
            expired={item.expired}
            open={item.open}
            panelItems={{
              confirmationNumber: item.confirmationIDNumber,
              contractName: item.contractName,
              policyPeriod: {
                start: item.start,
                end: item.end,
              },
              clientCompany: item.cedentCompany,
              githubUri: item.githubLink,
            }}
          >
            <div className="collapsible-body">
              <div className={!item.subPeriods.length ? "cards-first-row " : "cards-first-row-pending"}>
                <PaymentSummary
                  hasSubperiods={item.subPeriods.length}
                  content={{
                    reinsurerShare: item.reinsurerShare,
                    showReinsurerShare: item.reinsurerShare && item.reinsurerShare < 1,
                    subjectLoss: USDollar.format(item.subjectLoss || 0),
                    totalLimit: USDollar.format(item.totalLimit || 0),
                    progress: item.subjectLoss ? ((item.totalLimit - item.subjectLoss) * 100) / item.totalLimit : 0,
                    perEventLimit: USDollar.format(item.perStormLimit || 0),
                    perEventAttachment: USDollar.format(item.perStormAttachment || 0),
                    subPeriods: item.subPeriods,
                  }}
                />

                <ContractDocument
                  hasSubperiods={item.subPeriods.length}
                  downloadFile={requestDownload}
                  content={[
                    {
                      name: "Location Exposure and Payout Factors Table",
                      type: item.locationExposure?.type,
                      fileUri: item.locationExposure?.fileUri,
                      uid: item.locationExposure?.uid,
                    },
                    {
                      name: "Reinsurance Agreement",
                      type: item.reinsuranceContract?.type,
                      fileUri: item.reinsuranceContract?.fileUri,
                      uid: item.reinsuranceContract?.uid,
                    },
                  ]}
                />

                <Stakeholders
                  active={active || isSignedForDemo}
                  expired={item.expired}
                  content={{
                    broker: { ID: item.participants.brokerAddress, signedDate: null, showBroker: item.showBroker },
                    cedent: {
                      ID: item.participants.cedentAddress,
                      signedDate: newTimeStamps.cedentSignedTimestamp || item.timestamps.cedentSignedTimestamp,
                      isSigned: isSignedForDemo || newTimeStamps.cedentSigned || item.timestamps.cedentSigned,
                    },
                    reinsurer: {
                      ID: item.participants.reinsurerAddress,
                      signedDate: newTimeStamps.reinsurerSignedTimestamp || item.timestamps.reinsurerSignedTimestamp,
                      isSigned: isSignedForDemo || newTimeStamps.reinsurerSigned || item.timestamps.reinsurerSigned,
                      showReinsurer: item.showReinsurer,
                    },
                  }}
                />
              </div>

              {item.active || item.expired || showSignToUser
                ? item.storms && (
                    <StormTable
                      // NOTE -- Name needs to be the same as the item.storms properties
                      columns={[
                        {
                          name: "name",
                          title: "Storm Name",
                          tooltip: false,
                          downloadable: false,
                          hide: false,
                        },
                        { name: "date", title: "Date", tooltip: false, downloadable: false, hide: false },
                        {
                          name: "grossLoss",
                          title: "Parametric Modeled Loss",
                          tooltip: false,
                          downloadable: false,
                          hide: false,
                        },
                        {
                          name: "subjectLoss",
                          title: "Loss Amount",
                          tooltip: false,
                          downloadable: false,
                          hide: false,
                        },
                        {
                          name: "lossDetailFile",
                          title: "Loss Detail Report",
                          tooltip: false,
                          downloadable: true,
                          hide: isBroker,
                        },
                        {
                          name: "fileType",
                          title: "File Type",
                          tooltip: false,
                          downloadable: false,
                          hide: isBroker,
                        },
                        {
                          name: "fileDate",
                          title: "File Date",
                          tooltip: false,
                          downloadable: false,
                          hide: isBroker,
                        },
                      ]}
                      onExportTable={handleExport}
                      downloadFile={requestDownload}
                      uid={item.uid}
                      contractName={item.contractName}
                      listItems={item.storms}
                    />
                  )
                : ""}

              <div>
                <div className="disclaimer-message">
                  <p>
                    <strong>Disclaimer:</strong> This dashboard is provided for convenience only. The dashboard does not
                    constitute an Event Notice and does not relieve Centauri Specialty Insurance Company of having to
                    make an Event Notice under the Reinsurance Agreement. Arbol does not take any responsibility for any
                    inaccuracies in this information. To the extent any information in this dashboard conflicts with
                    anything in the Reinsurance Agreement or any agreement between Arbol Inc. and Centauri Specialty
                    Insurance Company, such agreement shall govern.
                  </p>
                </div>

                {!item.active && !item.expired && !showSignToUser && (
                  <SignCallToAction
                    onSign={() => signContract(isDemo ? DEMO_UID : item.uid)}
                    loading={loadingSignature}
                  />
                )}
              </div>
            </div>
          </Collapsible>
        );
      })}
    </div>
  );

  return (
    <>
      <Header loggedAs={loggedAs} isDemo={isDemo} />

      {loading ? (
        <div
          style={{
            margin: "20% auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" indicator={antIcon} />
        </div>
      ) : (
        renderContent()
      )}
    </>
  );
}
